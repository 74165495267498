<template>
<!--    <div class="page-container">-->
<!--        <md-app>-->
<!--            <md-app-toolbar class="md-primary">-->
<!--                <router-link :to="{name: 'dashboard.premium'}">-->
<!--                    <img src="https://rehab-connect.s3.amazonaws.com/assets/logo_home.svg" alt="Rehab.com Logo" class="rehab-logo">-->
<!--                </router-link>-->
<!--            </md-app-toolbar>-->



            <md-app-content v-if="funnelData">
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item md-xlarge-size-95 md-large-size-90 md-medium-size-90 md-small-size-100">
                        <div class="close" @click="close()" v-if="mobile"><md-icon>close</md-icon></div>
                        <div class="headers">
                            <h1>Set your monthly budget</h1>
<!--                            <h1 v-if="funnelData.goal==='clicks'">Select your monthly click budget</h1>-->
                            <h2>Set the amount you would like to spend</h2>
                        </div>


                        <div class="slider-container">
                            <span class="budget">{{ '$'+formatPrice(Math.ceil(funnelData.sliderAmount)) }}</span>
<!--                            <span class="perfacility">for {{funnelFacility.attributes.name}}</span>-->
                            <div class="slidecontainer">
                                <!-- CALL SLIDER -->
                                <input
                                        class="slider"
                                        type="range"
                                        v-model.number="funnelData.sliderAmount"
                                        :min="sliderMin"
                                        :max="sliderMax"
                                        :step="sliderStep"
                                >
                                <div class="typical"></div>
                                <div class="competitor">Typical range</div>
                            </div>

                            <!-- CALCULATE NUMBER OF CALLS / MONTH -->
                            <div
                                    v-if="
                                        funnelData.goal === 'calls' &&
                                        funnelData.sliderAmount <= sliderMax &&
                                        funnelData.sliderAmount>0
                                    "
                                    class="reach"
                            >
                                Receive up to {{  Math.floor(funnelData.sliderAmount/ fiveBudget.facilityBudgets.find(budget => budget.facilityId == this.funnelData.funnelFacility).singleCallCost) | callClickRange }} calls per month
                            </div>
                            <!-- CALCULATE NUMBER OF CLICKS / MONTH -->
                            <div
                                    v-if="
                                        funnelData.goal === 'clicks' &&
                                        funnelData.sliderAmount <= sliderMax &&
                                        funnelData.sliderAmount>0
                                    "
                                    class="reach"
                            >
                                Receive up to {{ Math.floor(funnelData.sliderAmount/ fiveBudget.facilityBudgets.find(budget => budget.facilityId == this.funnelData.funnelFacility).singleClickCost) | callClickRange }} clicks per month
                            </div>
                            <!-- CALCULATE NUMBER OF CALLS & CLICKS / MONTH -->
                            <div
                                    v-if="funnelData.goal === 'callsAndClicks'"
                                    class="reach"
                            >
                                Receive up to {{ calculateFacilityFunnelBudget(funnelData.sliderAmount).clicks }} clicks and  {{ calculateFacilityFunnelBudget(funnelData.sliderAmount).calls }} calls per month
                            </div>

                        </div>

                        <div class="next-btn">
                            <md-button
                                    :md-ripple="false"
                                    class="md-primary md-raised"
                                    @click="selectBudget"
                                    :disabled="!funnelData.sliderAmount"
                            >Continue</md-button>
                        </div>
                    </div>

                </div>
            </md-app-content>
<!--        </md-app>-->

<!--    </div>-->
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    export default {
        name: "SelectBudget",

        data() {
            return {
                sliderMin: 50,
                sliderMax: 10000,
                sliderStep: 1,
                funnelData: null,
                mobile: false
            }
        },



        computed: {
            ...mapGetters([
                'goPremiumFunnelInfo',
                'calculateBudget',
                'facilities',
                'calculateFacilityFunnelBudget'
            ]),
            fiveBudget(){
                return this.calculateBudget({numInteractions: 5})
            },
            funnelFacility(){
                return this.facilities.find(facility => facility.attributes.facilityId === this.goPremiumFunnelInfo.funnelFacility)
            }
        },

        created() {
            if( this.windowWidth <= 760 ) {
                this.mobile = true
            }  
            const funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
           // console.log('Funnel Data', JSON.parse(JSON.stringify(funnelData)))
            if(!funnelData.sliderAmount){
                //console.log('NO SLIDER AMOUNT ADDING')

                if(funnelData.goal == 'clicks'){
                    if(funnelData.budget == 0){
                         funnelData.sliderAmount = 3400;
                    }else{
                         funnelData.sliderAmount = funnelData.budget
                    }
                }
                else if(funnelData.goal == 'calls'){
                    if(funnelData.budget == 0){
                         funnelData.sliderAmount = 320;
                    }else{
                         funnelData.sliderAmount = funnelData.budget
                    }
                    this.sliderMin = 100;
                }else{
                      if(funnelData.budget == 0){
                         funnelData.sliderAmount = 3400;
                    }else{
                         funnelData.sliderAmount = funnelData.budget
                    }
                    this.sliderMin = 150;
                }

                //console.log('FUNNEL DATA WITH SLIDER AMOUNT', JSON.parse(JSON.stringify(funnelData)))
            }
            this.funnelData = funnelData;
        },

        mounted() {

            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            // CHANGE OVERLAY STYLE
            const all = document.getElementsByClassName('md-overlay');
            setTimeout(() => {
                for (const overlay of all) {
                    overlay.style.opacity = '0.8';
                }
            }, 10);
        },

        filters: {
            formatPrice: (value) => {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            callClickRange: (value) => {
                const low = Math.ceil(value / 2);
                const high = parseInt(value * 1.5);
                return `${value}`
            }
        },

        methods: {
            ...mapActions([
                'updateGoPremiumFunnelInfo'
            ]),
            selectBudget() {
                this.funnelData.budget = this.funnelData.sliderAmount;
                this.updateGoPremiumFunnelInfo(this.funnelData);
                this.$router.push({name: 'premium.funnel.checkout'})
            },
            formatPrice(value) {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            close(){
                this.$parent.$parent.$parent.$parent.$parent.showCustomBudgetDialog = false;
            },
            funnelSliderValues(){
                if(this.funnelData.goal === 'calls'){
                    return {
                        step: this.fiveBudget.facilityBudgets.find(budget => budget.facilityId == this.funnelData.funnelFacility).singleCallCost
                    }
                } else if(this.funnelData.goal === 'clicks'){
                    return {
                        step: this.fiveBudget.facilityBudgets.find(budget => budget.facilityId == this.funnelData.funnelFacility).singleClickCost
                    }
                }else if(this.funnelData.goal === 'callsAndClicks'){
                    return {
                        step: this.fiveBudget.facilityBudgets.find(budget => budget.facilityId == this.funnelData.funnelFacility).singleCallCost + this.fiveBudget.facilityBudgets.find(budget => budget.facilityId == this.funnelData.funnelFacility).singleClickCost
                    }
                }
            },
        }

    }
</script>

<style lang="scss" scoped>
    .md-toolbar {
        background-color: #FAFAFA !important;
    }

    .rehab-logo {
        height: 32px;
        margin-left: 55px;
    }

    .headers {
        text-align: center;

        h1{
            font-size: 36px;
            font-weight: 300;
            display: block;
            text-align: center;
            padding-top: 10px;
        }

        h2 {
            font-weight: 300;
            font-size: 24px;
            line-height: 26px;
        }

    }

    .next-btn {
        .md-button {
            height: 40px;
            padding-left: 15px;
            padding-right: 15px;
            box-shadow: none;
            color: #3671FF;
            border: 1px solid #3671FF;
            text-transform: none;
            margin-left: 0;
            margin-right: 0;
            margin-top: 30px;
            float: right;
        }

        .md-primary {
            color: #fff;
            border: none;
            background-color: #0078D3 !important;
        }
        .md-button.md-theme-default.md-raised[disabled] {
            background-color: #8C9296 !important;
            color: #fff;
        }
    }

    .options{
        .md-card-actions{
            margin-top: 20px;
        }

    }


    .slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        appearance: none;
        width: 25px;
        height: 25px;
        border-radius: 50%;
        background: #fff;
        cursor: pointer;
        box-shadow: 0px 0px 2px 2px #ccc;
    }

    .slider-container {
        margin-top: 36px;
        overflow: visible;
        padding-bottom: 10px;
        text-align: center;


        .slidecontainer {
            width: 100%;
        }
        .budget {
            font-size: 38px;
            font-weight: 700;
            color: var(--md-theme-default-primary);
            margin-bottom: 10px;
            width: 500px;
            cursor: pointer;
        }

        .perfacility {
            display: block;
        }

        .slider {
            margin-top: 28px;
            -webkit-appearance: none;
            width: 95%;
            height: 10px;
            border-radius: 5px;
            background: var(--md-theme-default-primary);
            outline: none;
            -webkit-transition: .2s;
            -webkit-transition: opacity .2s;
            transition: opacity .2s;
        }

        .typical {
            border-bottom: 1px solid #9c9c9c;
            width: 40%;
            margin-left: 20%;
            margin-right: auto;
            height: 10px;
            border-left: 1px solid #9c9c9c;
            border-right: 1px solid #9c9c9c;
            margin-top: 4px;
        }

        .competitor {
            margin-top: 10px;
            margin-left: 20%;
            margin-right: auto;
            width: 40%;
            color: #9c9c9c;
        }

        .reach {
            margin-top: 10px;
        }
    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .headers h1{
             width: 100%;
             line-height: 30px;
             font-size: 28px;
             letter-spacing: -1px;
             width: 100%;
             margin-left: auto;
             margin-right: auto;
             margin-top: 0;
             margin-bottom: 0;
        }
        .headers h2{
             font-size: 18px;
             font-weight: 300;
             line-height: 22px;
             width: 80%;
             margin-left: auto;
             margin-right: auto;
        }
        .slider-container .slider{
            width: 100%;
        }
        .md-alignment-top-center{
            position: relative;
        }
        .showCustomBudgetDialog{
            .md-layout-item{
                padding-right: 5px;
                padding-left: 5px;
            }
        }
        .close{
            position: absolute;
            right: -10px;
            top: -14px;
            color: #c6c6c6!important;
        }
        .md-icon.md-theme-default.md-icon-font{
            color: #c6c6c6!important;
        }
    }


</style>