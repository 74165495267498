<template>
    <div>
        <md-app>
            <md-app-toolbar class="md-primary" v-if="!mobile">
                <router-link :to="{name: 'dashboard.premium'}">
                    <img src="https://rehab-connect.s3.amazonaws.com/assets/logo_home.svg" alt="Rehab.com Logo" class="rehab-logo">
                </router-link>
            </md-app-toolbar>



            <md-app-content v-if="funnelData">
                <router-link :to="'/premium/funnel/select-goal'" v-if="!mobile">
                    <div class="backArrow" to = ""><md-icon>keyboard_arrow_left</md-icon></div>
                </router-link>
                <div class="md-layout md-alignment-top-center">
                    <div class="md-layout-item md-xlarge-size-60 md-large-size-60 md-medium-size-60 md-small-size-100">
                        <div class="headers">
                            <h1 v-if="funnelData.goal==='calls'">Select your monthly call budget</h1>
                            <h1 v-if="funnelData.goal==='clicks'">Select your monthly click budget</h1>
                            <h1 v-if="funnelData.goal==='callsAndClicks'">Select your monthly click and call budget</h1>
                            <h2>Customize the amount you would like to spend</h2>
                        </div>

                        <!-- CALLS BUDGET -->
                        <div class="left-side" v-if="funnelData.goal==='calls'">
                            <div
                                    class="box"
                                    @click="budgetClick(320)"
                                    :class="{'selected': funnelData.budget === 320}"
                            >
                                <span><strong>{{ 320 | formatPrice}}<span class="raised-text">per listing</span></strong></span>
                                <div v-if="funnelData.goal==='calls'">Receive up to {{ calculateFacilityFunnelBudget(320) | callClickRange }} calls per month</div>
                            </div>
                            <div
                                    class="box"
                                    @click="budgetClick(630)"
                                    :class="{'selected': funnelData.budget === 630}"
                            >
                                <span><strong>{{ 630 | formatPrice}}<span class="raised-text">per listing</span></strong><span class="recommended"  v-if="mobile">Recommended</span></span>
                                <span class="recommended" v-if="!mobile">Recommended for you</span>
                                <div v-if="funnelData.goal==='calls'">Receive up to {{ calculateFacilityFunnelBudget(630) | callClickRange }} calls per month</div>

                            </div>
                            <div
                                    class="box"
                                    @click="budgetClick(930)"
                                    :class="{'selected': funnelData.budget === 930}"
                            >
                                <span><strong>{{ 930 | formatPrice}}<span class="raised-text">per listing</span></strong></span>
                                <div v-if="funnelData.goal==='calls'">Receive up to {{ calculateFacilityFunnelBudget(930) | callClickRange }} calls per month</div>

                            </div>
                            <span
                                    class="customize"
                                    @click="showCustomBudgetDialog = true"
                            >I would like to customize my budget</span>
                        </div>

                        <!-- CLICKS BUDGET -->
                        <div class="left-side" v-if="funnelData.goal==='clicks'">
                            <div
                                    class="box"
                                    @click="budgetClick(1250)"
                                    :class="{'selected': funnelData.budget === 1250}"
                            >
                                <span><strong>{{ 1250 | formatPrice}}<span class="raised-text">per listing</span></strong></span>
                                <div v-if="funnelData.goal==='clicks'">Receive up to {{calculateFacilityFunnelBudget(1250) | callClickRange }} clicks per month</div>

                            </div>
                            <div
                                    class="box"
                                    @click="budgetClick(2490)"
                                    :class="{'selected': funnelData.budget === 2490}"
                            >
                                <span class="recommended" v-if="!mobile">Recommended for you</span>
                                <span><strong>{{ 2490 | formatPrice}}<span class="raised-text">per listing</span></strong><span class="recommended"  v-if="mobile">Recommended</span></span>
                                <div v-if="funnelData.goal==='clicks'">Receive up to {{ calculateFacilityFunnelBudget(2490) | callClickRange }} clicks per month</div>

                            </div>
                            <div
                                    class="box"
                                    @click="budgetClick(3650)"
                                    :class="{'selected': funnelData.budget === 3650}"
                            >
                                <span><strong>{{ 3650 | formatPrice}}<span class="raised-text">per listing</span></strong></span>
                                <div v-if="funnelData.goal==='clicks'">Receive up to {{ calculateFacilityFunnelBudget(3650) | callClickRange }} clicks per month</div>


                            </div>
                            <span
                                    class="customize"
                                    @click="showCustomBudgetDialog = true"
                            >I would like to customize my budget</span>
                        </div>


                        <!-- CALLS AND CLICKS BUDGET -->
                        <div class="left-side" v-if="funnelData.goal==='callsAndClicks'">
                            <div
                                    class="box"
                                    @click="budgetClick(1570)"
                                    :class="{'selected': funnelData.budget === 1570}"
                            >
                                <span><strong>{{ 1570 | formatPrice}}<span class="raised-text">per listing</span></strong></span>
                                <div v-if="funnelData.goal==='callsAndClicks'">Receive up to {{ calculateFacilityFunnelBudget(1570).clicks | callClickRange }} clicks and {{ calculateFacilityFunnelBudget(1570).calls | callClickRange }} calls per month</div>
                            </div>
                            <div
                                    class="box"
                                    @click="budgetClick(3120)"
                                    :class="{'selected': funnelData.budget === 3120}"
                            >
                                <span class="recommended" v-if="!mobile">Recommended for you</span>
                                <span><strong>{{ 3120 | formatPrice}}<span class="raised-text">per listing</span></strong><span class="recommended"  v-if="mobile">Recommended</span></span>

                                <div v-if="funnelData.goal==='callsAndClicks'">Receive up to {{ calculateFacilityFunnelBudget(3120).clicks | callClickRange }} clicks and {{ calculateFacilityFunnelBudget(3120).calls | callClickRange }} calls per month</div>
                            </div>
                            <div
                                    class="box"
                                    @click="budgetClick(4590)"
                                    :class="{'selected': funnelData.budget === 4590}"
                            >
                                <span><strong>{{ 4590 | formatPrice}}<span class="raised-text">per listing</span></strong></span>

                                <div v-if="funnelData.goal==='callsAndClicks'">Receive up to {{ calculateFacilityFunnelBudget(4590).clicks | callClickRange }} clicks and {{ calculateFacilityFunnelBudget(4590).calls | callClickRange }} calls per month</div>
                            </div>
                            <span
                                    class="customize"
                                    @click="showCustomBudgetDialog = true"
                            >I would like to customize my budget</span>
                        </div>


                        <div class="right-side" v-if="!mobile">
                             
                            <h4>What you pay for:</h4>
                            <p v-if="funnelData.goal == 'calls' ">Pay only when people call.</p>
                            <p v-else-if="funnelData.goal == 'clicks' ">Pay only when people click-through to your website.</p>
                            <p v-else >Pay only when people click-through to your website or call.</p>

                            <h4>When am I billed:</h4>
                            <p>Pay after you reach your budget threshold or every month, whichever comes first.</p>

                            <h4>If you change your mind:</h4>
                            <p>Launch your campaign, but no need to commit. Change your budget anytime.</p>
                        </div>


                        <!-- CUSTOM BUDGET DIALOG-->
                        <md-dialog :md-active.sync="showCustomBudgetDialog" class="showCustomBudgetDialog">
                            <select-custom-campaign-budget></select-custom-campaign-budget>
                        </md-dialog>


                        <div class="next-btn">
                            <md-button
                                    :md-ripple="false"
                                    class="md-primary md-raised"
                                    @click="selectBudget"
                                    :disabled="!funnelData.budget"
                            >Continue</md-button>
                        </div>


                    </div>

                </div>
            </md-app-content>
        </md-app>

    </div>
</template>

<script>
    import {mapGetters, mapActions} from 'vuex'
    import SelectCustomCampaignBudget from "./SelectCustomCampaignBudget";
    export default {
        name: "SelectBudget",

        components: {
            SelectCustomCampaignBudget
        },

        data() {
            return {
                funnelData: null,
                showCustomBudgetDialog: false,
                mobile: false
            }
        },

        computed: {
            ...mapGetters([
                'goPremiumFunnelInfo',
                'calculateFacilityFunnelBudget'
            ]),
        },
        created(){
           if( this.windowWidth <= 760 ) {
                this.mobile = true
            }  
        },
        mounted() {
            this.$watch('windowWidth', (value, oldValue) => {

                if(value <= 760){
                    this.mobile = true;
                }else{
                    this.mobile = false;
                }
            
            })
            const funnelData = JSON.parse(JSON.stringify(this.goPremiumFunnelInfo))
            funnelData.currentStep = this.$route.name;
            this.funnelData = funnelData;
            this.funnelData.budget = 0;
            this.funnelData.sliderAmount = 0;
            this.updateGoPremiumFunnelInfo(this.funnelData);
        },

        filters: {
            formatPrice: (value) => {
                let val = (value / 1).toFixed(0).replace(',', '.')
                return "$" + val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")
            },
            callClickRange: (value) => {
                const low = Math.ceil(value / 2);
                const high = parseInt(value * 1.5);
                return `${value}`
            }
        },

        methods: {
            ...mapActions([
                'updateGoPremiumFunnelInfo'
            ]),
            selectBudget() {
                this.updateGoPremiumFunnelInfo(this.funnelData);
                this.$router.push({name: 'premium.funnel.checkout'})
            },
            budgetClick(budget){
                //console.log('BUDGET CLICK', budget);
                this.funnelData.budget = budget;
            }
        }

    }
</script>

<style lang="scss" scoped>
    .page-container, .md-app, .md-app-container, .md-app-scroller, .md-layout-column, .md-flex, .md-theme-default, .md-scrollbar, .md-content, .md-drawer, .md-theme-default, .md-tabs-content {
        background-color: #ffffff;
    }

    .choice-tabs {
        .md-tab {
            background-color: #fff;
        }
    }

    .md-toolbar {
        background-color: #FAFAFA !important;
    }

    .showCustomBudgetDialog{
        top:337px;
        width: 700px
    }

    .rehab-logo {
        height: 32px;
        margin-left: 55px;
    }

    .md-primary{
        border: 1px solid #eee;
    }

    .headers {
        text-align: center;

        h1{
            font-size: 36px;
            font-weight: 300;
            display: block;
            text-align: center;
            padding-top: 0;
            margin-top: 0;
        }

        h2 {
            font-weight: 300;
            font-size: 24px;
            line-height: 26px;
            margin-bottom: 30px;
        }

    }

    .right-side{
        /*background-color: #F6F6F6;*/
        /*border-radius: 3px;*/
        width: 40%;
        float: left;
        text-align: left;
        margin-top: 2px;
        color: #020202;
        padding-top: 25px;
        padding-bottom: 12px;
        padding-right: 30px;
        padding-left: 18px;

        h4 {
            font-weight: 500;
            font-size: 16px;
            margin-bottom: 0;
            margin-top: 0;
            color: #000;
        }
        p{
            margin-top: 10px;
            font-size: 16px;
            display: block;
            color: #434343;
            margin-bottom: 30px;
        }
        .customize:hover{
            text-decoration: underline;

        }
    }
    .left-side{
        width: 60%;
        float: left;
        text-align: left;
        padding-left: 30px;
        margin-top: 20px;

        .customize{
            padding-top: 0px;
            padding-left: 10px;
            display: block;
            cursor: pointer;
            color: #434242;
            font-weight: 200;
            font-size: 14px;
            font-family: "Lato", sans-serif;
            -webkit-font-smoothing: subpixel-antialiased;
            -moz-osx-font-smoothing: grayscale;
        }
    }
    .left-wide{
        float: left;
        text-align: left;
        padding-left: 30px;
        padding-right: 10px;
        margin-top: 20px;
        width: 100%;
        .customize{
            margin-top: 80px;
            cursor: pointer;
            opacity: 0.5;

        }
        .box{
            height: 153px;
            padding-left: 20px;
            padding-right: 20px;

            ul{
                float: left;
                width: 80%;

                li{
                    line-height: 26px; margin-left: -22px;
                }
            }
            .price{
                font-size: 39px;
                padding-top: 8px;
                font-weight: bold;
                color: var(--md-theme-default-primary)
            }
            .title{
                padding-top: 4px;
                font-size: 18px;
                font-weight: bold;
            }
            .rightInfo{
                text-align: right;
                .price{
                    padding-bottom: 8px;
                }
            }
        }
    }
    .md-dialog-actions{
        top:456px;
        position: absolute;
        left:unset;
        padding-left: 30px;
    }
    .box{
        color: #020202;
        border: 2px solid #F0F0F0;
        border-radius: 5px;
        padding: 15px;
        margin-bottom: 14px;
        margin-right: 20px;
        strong{
            color: #020202;
            font-size: 28px;
            .raised-text{
                font-size: 14px;
                top: -8px;
                left:4px;
                position: relative;
                display: inline;
                font-weight: 300;
            }
        }
        span{
            display: block;

            font-size: 14px;
            padding-top:2px;
        }
        div {
            margin-top: 10px;
        }

        .recommended{
            float: right;
            background-color: var(--md-theme-default-primary);
            color: white;
            padding:2px;
            text-align: center;
            border-radius: 10px;
            width: 144px;
            font-size: 12px;
            margin-bottom: 8px;
        }
    }
    .box:hover{
        border: 2px solid var(--md-theme-default-primary);
        cursor: pointer;
    }

    .box.selected{
        border: 3px solid var(--md-theme-default-primary);
    }

    .options{
        height: 510px;
        min-height: 510px;
        width: 820px;
        padding-left: 14px;

        .left-wide{
            margin-top: 18px!important;
            .box{
                height: unset!important;
            }
        }
        .md-dialog-actions{
            top:440px;
            padding-left: 32px;
        }
        .recommended{
            margin-left: 10px;
            float: left;
            margin-top: 1px;
        }
        .title{
            float: left;
        }
        ul{
            margin-top: 0px;
            clear: both;
            width: 100%!important;
            margin-bottom: 0px;
            li{
                color: #545454;
            }
        }
        .price{
            color: #545454!important;
            font-weight: 500!important;
            font-size: 34px!important;
        }
        .box{
            display: table;
            width: 96%;
        }
        .rightInfo{
            display: table-cell;
            vertical-align: middle;
        }
        .go{
            margin-top: 10px;
        }
    }

    .next-btn {
        .md-button {
            height: 44px;
            padding-left: 15px;
            padding-right: 15px;
            box-shadow: none;
            color: #3671FF;
            border: 1px solid #3671FF;
            text-transform: none;
            margin-left: 0;
            margin-right: 0;
            float: right;
            width: 130px;
        }

        .md-primary {
            color: #fff;
            border: none;
            background-color: #0078D3 !important;
        }
        .md-button.md-theme-default.md-raised[disabled] {
            background-color: #8C9296 !important;
            color: #fff;
        }
    }

    .md-layout-item.md-large-size-60{
        max-width: 840px;
        min-width: 840px;
    }

    .backArrow{
   
        font-weight: 600;
        font-size: 20px;
        position: absolute;
        top: 360px;
        left: 120px;
        cursor: pointer;
        .md-icon {
            color: #0078D3;
        }
    }
    @media only screen and (max-width: 1070px){

        .backArrow{
            display: none;
        }

    }

    @media only screen and (max-width: 760px), 
    screen and  (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 2),
    screen and (max-width: 375px) and (max-height: 812px) and (-webkit-device-pixel-ratio: 3),
    screen and (max-width: 414px) and (max-height: 896px) and (-webkit-device-pixel-ratio: 3)
    {
        .md-layout-item.md-large-size-60{
           
            min-width: unset;
            max-width: unset;
            width: 100%;
        }
        .headers, .left-side{
            width: 100%;
            padding:0;
            margin:0;
        }
        .headers h1{
             width: 100%;
             line-height: 30px;
             font-size: 28px;
             letter-spacing: -1px;
             width: 90%;
             margin-left: auto;
             margin-right: auto;
        }
        .headers h2{
             font-size: 18px;
             font-weight: 300;
             line-height: 22px;
        }
        .md-app-content{
            padding-top: 20px;
            padding-left: 15px;
            padding-right: 15px;
            margin: 0;
            height: 100vh;
        }
        .box{
            margin-right: 0;
        }
        .md-button{
            margin-top: 24px;
        }
        .box .recommended{
            float: unset;
            background-color: var(--md-theme-default-primary);
            color: white;
            padding:2px;
            text-align: center;
            border-radius: 10px;
            width: 106px;
            font-size: 12px;
            margin-bottom: unset;
            margin-top: unset;
            display: inline-flex;
            margin-left: 20px;
            position: relative;
            top: -10px;
            text-align: center;
            padding-left: 10px;
        }

        .left-side .customize{
            padding-left: 16px;
        }
    }
</style>